

.form{
    @apply w-full pl-5 md:pl-0;
}
.auth-input-group{
    @apply lg:flex mt-6 mb-0 items-center w-full px-3 md:px-8 lg:px-0;
}
.input-group{
    @apply lg:flex my-6 items-center w-full px-3 md:px-8 lg:px-0;
}
.input-group-resource{
    @apply lg:flex items-center w-full px-3 md:px-8 lg:px-0;
}
.resource-checks-input-group{
    @apply flex w-full px-3 my-3 md:px-8 lg:px-0;
}
.resource-checks-input-group .check-input-label {
    @apply  inline  text-gray-text text-right mr-3 text-base 2xl:text-lg w-auto lg:w-1/5;
}
.resource-checks-input-group .input-checks-tag {
    @apply  flex px-3 rounded-lg w-full lg:w-3/4; 
}
.input-group-d{
    @apply md:flex my-3 items-center mb-6;
}
.input-tag{
   @apply bg-white block px-3 py-3 my-3 rounded-lg w-full; 
}
.input-tag-d{

   @apply bg-white block px-3 py-3 my-3 md:my-0 rounded-md w-full md:w-8/12; 
}
.input-label{
    @apply  inline  text-gray-text text-right mr-5 text-base 2xl:text-lg w-full md:w-1/4;
    /* width:25% */
}

.state-label{

    @apply  inline  text-gray-text text-right lg:ml-5 lg:mr-2 mx-0 text-base 2xl:text-lg;
    /* width:45% */
}
.contact-label{

    @apply  inline  text-gray-text text-right lg:mr-3 mx-0  text-base 2xl:text-lg ;
    width:55%;
}
.input-label-d{

    @apply  text-gray-text text-right mr-5 text-base 2xl:text-lg;
    width:30%;
}

.re-width{
    width: 44.5%;
}

.select-tag{
    width: 63%;
}


.options{
    @apply text-gray-text text-lg;
}

.message{
    /* color: green; */
    padding: 0 2rem 1rem 2rem;
    text-align: right;
}


select{

    background: url('dropdown.svg') no-repeat right #ddd;
    background-size: 25px;
    background-position-x: 95%;
  
}
.radio-group-label{
    @apply text-gray-text md:text-right mr-5 lg:text-lg;
    width: 45%;
}
.file-input{
    /* display: none; */
    width: 0px;
    height: 0px;
    
}
.radio-label{
    @apply relative text-gray-text md:text-right lg:text-lg ml-12 ;
}
.radio-label::before{
    content: " ";
    background-color: white;
    @apply md:rounded-lg rounded-md w-5 h-5 md:w-8 md:h-8 absolute top-0 -left-12;
}
.partner-radio-label{
    @apply relative text-gray-text md:text-left lg:text-lg ml-12 ;
}
.partner-radio-label::before{
    content: " ";
    background-color: white;
    @apply md:rounded-lg rounded-md w-5 h-5 md:w-8 md:h-8 absolute top-0 -left-12;
}

.radio-list-item{
    @apply flex w-full flex items-end;
    align-items:end;
}

.radio-list-item-j{
    width: 30%;
}
.radio-list-item input:checked + .radio-label::before{
   @apply bg-red-primary;
}
.radio-list-item-j input:checked + .radio-label::before{
   @apply bg-red-primary;
}
.radio-list-item input:checked + .partner-radio-label::before{
   @apply bg-red-primary;
}
.radio-list-item-j input:checked + .partner-radio-label::before{
   @apply bg-red-primary;
}

.radio-input{
    width: 0.1px;
    height: 0.1px;
    position: relative;
}

.button-div{
    width: 70%;
}

.error{
    @apply text-red-primary md:pl-10;
}

@media screen and (max-width:768px){
    .select-tag{
       width: 76%;
    }
    .input-label-d{

       @apply text-left mb-5;
       width: 100%;
    }
    .button-div{
        width: 100%;
    }
    
}

@media screen and (max-width:1024px){
    .re-width{
        width: 100%;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}