@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
    pointer-events: unset !important;
}

/* adding fonts below */
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratLight.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratRegular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratMedium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratBold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/MontserratExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
}

/* //adding fonts above */

html, body, #root{
    height: 100%;
}

p{
    /* @apply text-gray-text; */
}
#scrollP:hover{
    overflow-y: scroll;
}
/* span{
    font-family: "Montserrat" !important;
    @apply text-gray-text ;
}

p{
    font-family: "Montserrat" !important;
} */

ul{
    list-style-type: disc;
    margin-left: 1rem;
}
.job-disc ul li{
    font-family: "Montserrat" !important;
    color: #4d4d4d !important;
}

.job-disc font{
    font-family: "Montserrat" !important;
    font-size:1.2rem !important;
    color: #4d4d4d !important;

}
.job-disc span{
    font-family: "Montserrat" !important;
    font-size:1.2rem !important;
    color: #4d4d4d !important;
    @apply text-gray-text 2xl:text-lg leading-relaxed;
}
ol{
    list-style-type: decimal;
    margin-left: 1rem;
}

.search-form{
    position: relative;
    top: 10%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* width: 50%; */
}
.search-result{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
   
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 150px!important;
}

/* menu btn */
.menu-btn div:nth-child(1){
    top: 0%;
    transform: translateY(0%) rotate(0);
}
.menu-btn div:nth-child(2){
    top: 50%;
    transform: translateY(-50%) rotate(0);
}
.menu-btn div:nth-child(3){
    top: 100%;
    transform: translateY(-100%) rotate(0);
}
.menu-btn-active div:nth-child(1){
    top: 50%;
    transform: translateY(-50%) rotate(225deg);
}
.menu-btn-active div:nth-child(2){
    top: 50%;
    transform: translateY(-50%) rotate(225deg);
}
.menu-btn-active div:nth-child(3){
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
}
.sidebar{
    width: 320px;
    z-index: 100;
    overflow-y: auto;
}

.overlay-z{
    z-index: 99;
}

.nav-z{
    z-index: 101;
}
.section-padding{
    @apply lg:pt-36 px-20;
}

.mobile-top-p{
    padding-top: 110px;
}
.mobile-x-p{
    padding-left: 20px;
    padding-right: 20px;
}

.mobile-p{
    padding-left: 20px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-top: 110px;
}

.devices-product-image{
    width: 80%;
    max-width: 400px;
}

.navbar-padding{
    @apply pt-32 xl:pt-40 2xl:pt-52
}

.academy-padding{
    @apply pt-40 xl:pt-48 2xl:pt-60
}

.section-padding-top{
    @apply pt-32 xl:pt-40 2xl:pt-52 pb-2 2xl:pb-24 ;
}

.section-padding-horizontal{
    @apply lg:px-20;
}


.social-link{
    @apply transition text-lg xl:text-xl 2xl:text-2xl text-blue-primary hover:text-red-primary;
}

.social-link:hover svg{
    fill: #a52337!important;
}

.logo{
    max-width: 270px;
}

.landing-video{
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-37%, -45%);
}

.landing-video-2{
    width: 40%;
    right: 0%;
    top: 50%;
    transform: translate(0%, -50%);
}

.mobile-max-height{
    max-width: 500px;
}


.slider-image{
    max-height: 200px;
    /* width: 100%; */
}
@media screen and (min-width:1536px){
    .slider-image{
        max-height: 350px;
        /* width: 100%; */
    }
}

.second-section-image, .third-section-image{
    width: 500px;
    max-width: 500px;
}



.line-height{
    line-height: 2 !important;
}

.fourth-image{
    width: 80%;
    max-width: 1000px;
}

.flex-bigger{
    flex-basis: 70%;
}

.flex-smaller{
    flex-basis: 15%;
}

.mobile-logo{
    /* position: absolute; */
    left: 0%;
    transform: translateX(0%);
}

.heightCardsVideoMediaLibrary
{
    height: 10rem;
}

@media screen and (max-width:340px){
    .logo{
        max-width: 210px;
    }
}

@media screen and (max-width:768px){
    .tm{
        top: -10px;
        font-size: 8px;
    }
}
@media screen and (max-width:640px){
    .tm{
        top: -7px;
    }
}

@media screen and (min-width:1024px){
    .mobile-logo{
        position: static;
        left: 0;
        transform: translateX(0);
    }
    .logo{
        max-width: 250px;
    }
}

@media screen and (min-width:1280px){
    .logo{
        max-width: 250px;
    }
    .second-section-image{
        width: 400px;
        max-width: 400px;
    }
    
    .third-section-image{
        width: 440px;
        max-width: 440px;
    }
}
@media screen and (min-width:1536px){
    .logo{
        max-width: 300px;
    }
    
}

.backgroundCardHomeMediaLibrary
{
    height: 20rem;
}

.docImageVideoMediaLibrary
{
    height:20rem;
}

.swiper-button-prev, .swiper-button-next
{
    color: white !important;
    position: absolute;
    left: 5px;
    /* z-index: 999; */
}

.swiper-container{
    height:100%;
    width: 100%;
}

.swiper-button-next-custom{
    position: absolute;
    top:10% !important;
    right: 25% !important;
    z-index: 8 !important; 
    color: gray !important;
}

#sliderVideoMediaLibrary div div .swiper-slide
{
    width: 10rem !important;
}

#sliderVideoMediaLibrary .swiper-container
{
    margin: 0 2px !important;
    width: 100% !important;
    background-color: white;
}

@media screen and (max-width:600px)
{
    .docImageVideoMediaLibrary
    {
        height: 15rem;
    }
}

 @media screen and (min-width:768px){
    .swiper-button-next-custom{
       right: 40% !important;
    }

    .backgroundCardHomeMediaLibrary
    {
        height: 35rem;
    }
}
/*@media screen and (max-width:640px){
    .swiper-button-next-custom{
       right: 35% !important;
    }
}
@media screen and (max-width:512px){
    .swiper-button-next-custom{
       right: 20% !important;
    }
} */
.swiper-button-prev-custom{
    position: absolute;
    top:10% !important;
    left: 35% !important;
    z-index: 8 !important;
    color: gray !important;
}

@media screen and (min-width:768px){
    .swiper-button-prev-custom{
       left: 40% !important;
    }
}
/* @media screen and (max-width:640px){
    .swiper-button-prev-custom{
       left: 35% !important;
    }
}
@media screen and (max-width:512px){
    .swiper-button-prev-custom{
       left: 20% !important;
    }
} */


.skew-div:after{
    content: "";
    height: 100%;
    width: 75%;
    position: absolute;
    top: 0;
    z-index: -1;
}

.skew-right:after{
    left: 0;
    transform: translateX(-25%) skewX(18deg);
    background: #043c7b;
}
.skew-left:after{
    right: 0;
    transform: translateX(25%) skewX(18deg);
    background: #f0f0f0;
}



/* DEVICE DETAIL INDIVIDUAL STYLES START */

.device-hero-section{
    min-height: 508px;
}

@media screen and (max-width:1536px){
    .device-hero-section{
        min-height: 350px;
    }
}

@media screen and (max-width:1280px){
    .device-hero-section{
        min-height: 280px;
    }
}

@media screen and (max-width:1024px){
    .device-hero-section{
        background-image: none !important;
    }
}

/* DEVICE DETAIL INDIVIDUAL STYLES END */


/*  INDIVIDUAL TEAM MEMBER STYLES START */

.showOverlay{
   
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.5s;
}
.hideOverlay{
    position: absolute;
    top: 0;
    right: -100%;
    transition: all 0.5s;
    /* display: none; */
}

.showBackdrop{
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9;
}
.hideBackdrop{
    visibility: hidden;
}

.cardTeamBlock{
    width: 250px;
    height: 320px;
    margin-right: 2.5rem;
}

@media (min-width:580px) and (max-width:950px){
    .cardTeamBlock{
        margin-right: 0.5rem;
    }
}

@media screen and (max-width:579px){
    .cardTeamBlock{
        margin-right: 0rem;
    }
}



.containerCardTeamBlock > div{
    max-width: 1900px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: auto;
}

.cardTeamBlock:last-child{
    visibility: hidden!important;
}



@media (min-width:1257px) {
    .containerCardTeamBlock > div::after{
        content: "";
        flex: auto;
    }
}

@media (min-width:1530px){
    .cardTeamBlock:nth-last-child(3){
        margin-right: 4%;
    }
}


@media screen and (max-width:612px){
    .containerCardTeamBlock > div, .containerCardTeamBlock > div::after{
        justify-content: center;
        flex: none;
    }
}


.active_link:after{
    content: "";
    height: 40px;
    width: 3px;
    position: absolute;
    left: -8px;
    bottom: 0;
    transform: translateY(50%);
    background: #a52337;
 
}

/*  INDIVIDUAL TEAM MEMBER STYLES START */





/* LANDING PAGE GIF START */

.home-landing-gif{
    transform: translateY(-45%)
}


/* LANDING PAGE GIF END */


/* Banner Heading Styles */

.banner-image{
    width: 500px;
    max-width: 500px;

}

@media screen and (max-width:460px){
    .banner-image{
        width: 300px;
        max-width: 300px;
    }
}

.tab-bar-shadow{
    box-shadow: -4px 0px 10px rgb(0 0 0 / 0.2) inset;
    
}
.heading-container{
    @apply flex-grow order-2 md:order-1 text-center md:text-left pb-12 lg:pl-10;
}
.miami-heading-container{
    @apply w-11/12 md:w-6/12 lg:w-5/12 mx-auto md:mx-0 pb-10 order-2 md:order-1 ;
}
.product-banner-heading{
    @apply text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-extrabold uppercase text-white;
}
.miami-product-banner-heading{
    @apply text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-extrabold pl-3 uppercase text-blue-primary;
}
.other-banner-heading{
    @apply text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-extrabold uppercase text-gray-text tracking-wider mb-3;
}
.product-banner-subheading{
    @apply uppercase mt-3 text-xl md:text-3xl text-white;
}
.miami-product-banner-subheading{
    @apply  md:mt-3 text-xl text-left ml-14 md:text-2xl xl:text-4xl text-black font-bold;
}


.treatmentPattern{
    
    background-image: url('../src/assets/images/pattern.png');
    background-position:"115%";
    @apply bg-contain bg-no-repeat bg-right;
}
@media screen and (max-width:500px){
    .treatmentPattern{
        background-image: none;
    }
    
}
.description-heading{
    @apply w-full pr-10  xl:mb-0 text-gray-text font-bold text-2xl xl:text-2xl mb-10 lg:mb-0;
}

.description-p{
    @apply text-gray-text leading-relaxed xl:text-2xl;

    font-size:1.25rem;
}

.aortic-stenosis-section-padding{
    @apply px-3 md:px-8 py-8;
}

.second-section-treatment-aortic-stenosis{
    max-height: auto;
}

@media screen and (min-width:768px){
    .second-section-treatment-aortic-stenosis{
        max-height: 310px;
    }
}

.feature-heading-a1{
    @apply text-xl xl:text-2xl 2xl:text-3xl text-red-primary font-bold mb-3;
}
.feature-heading-miami{
    @apply text-xl xl:text-2xl 2xl:text-3xl text-blue-primary font-bold mb-3;
}
.feature-heading-a2{
    @apply uppercase font-medium text-red-primary mb-4 text-lg 2xl:text-xl;
 }
.feature-heading-patient{
    @apply uppercase font-medium text-red-primary mb-4 text-base md:text-lg 2xl:text-xl;
 }
 .feature-heading-patient-p{
    @apply font-medium text-red-primary mb-4 text-base md:text-lg 2xl:text-xl;
 }
.feature-heading-a3{
    @apply uppercase font-bold text-red-primary mb-2 text-base 2xl:text-lg ;
 }

 .feature-heading-a4{
    @apply uppercase font-bold text-red-primary mb-4 text-lg 2xl:text-2xl;
 }
 .feature-heading-pc{
     @apply font-bold text-red-primary mb-4 text-lg lg:text-2xl 2xl:text-3xl
 }

.feature-heading-m1{
    @apply text-xl xl:text-2xl 2xl:text-3xl text-blue-primary font-bold mb-5;

}
.feature-heading-m2{
   @apply uppercase font-medium text-blue-primary mb-4 text-lg 2xl:text-xl;
}
.icon-shadow{
    box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.3) ;
}

.feature-p-text{
    @apply text-sm 2xl:text-base text-gray-text;
}
.feature-p-text-pc{
    @apply text-sm lg:text-lg text-gray-text;
}

.blurr .blur-c  {
    @apply filter blur-sm;
}


@media screen and (max-width:1024px){
    .bar-height{
        height: 20px;
    }
}

.ul-red-color
{
    list-style: none;
}

.ul-red-color li:before
{
    content: "\2022";
    color: #a52337;
    font-weight: bold;
    display: inline-block; 
    width: 0.8em;
    margin-left: -1em;
    font-size:18px;
}

.fontSizeAorticStenosis
{
    font-size: 0.7vw!important;
}

@media screen and (max-width:767px){
    .fontSizeAorticStenosis
    {
        font-size: 2vw!important;
    }

    .heightCardsVideoMediaLibrary
    {
        height: 6.5rem;
    }
}

@media (min-width:768px) and (max-width:1023px){
    .fontSizeAorticStenosis
    {
        font-size: 0.8vw!important;
    }

    .heightCardsVideoMediaLibrary
    {
        height: 8rem;
    }
}

.tableBluePrimary > tbody td
{
    border: 1px solid #004180;
    padding: 15px;
}

.tableBluePrimary > tbody th
{
    border: 1px solid #004180;
    margin-top: -1px!important;
    margin-left: -1px!important;
    padding: 15px;
    background-color: #0051a06e;
}

.tableBluePrimaryBackground
{
    background-color: #0051a06e;
}

.privacyPolicyTable
{
    display: revert;
}

.heightVideo
{
    min-height: 500px!important;
}

.textValuesAboutUsPage
{
    height: 170px;
}

.indicatorHomePage
{
    border-radius: 50%;
    width: 10px;
    height: 10px; 
    cursor: pointer;
    margin: 10px;
    border: 0.6px solid white;
}

.indicatorHomePageInactive
{
    background: #043c7b;
}

.indicatorHomePageActive
{
    background: #a52337;
}

@media screen and (max-width:767px){
    .privacyPolicyTable
    {
        display: none;
    }

    .heightVideo
    {
        min-height: 20rem!important;
    }
}


@media (min-width:1899px)
{
    .textValuesAboutUsPage
    {
        height: 150px;
    }

    .heightCardsVideoMediaLibrary
    {
        height: 9rem;
    }
}

@media (min-width:1300px) and (max-width:1499px)
{
    .textValuesAboutUsPage
    {
        height: 210px;
    }

    .heightCardsVideoMediaLibrary
    {
        height: 10rem;
    }
}

@media (min-width:1150px) and (max-width:1299px)
{
    .textValuesAboutUsPage
    {
        height: 240px;
    }

    .heightCardsVideoMediaLibrary
    {
        height: 10rem;
    }
}

@media (min-width:1024px) and (max-width:1149px)
{
    .textValuesAboutUsPage
    {
        height: 310px;
    }

    .heightCardsVideoMediaLibrary
    {
        height: 10rem;
    }
}

@media (max-width:1023px)
{
    .textValuesAboutUsPage
    {
        height: 100%;
    }
}

.opacityPopUpDisclaimer
{
    opacity: 0.1;
}

#divTimeStampDesktop::-webkit-scrollbar
{
    width: 5px;
}

#divTimeStampDesktop::-webkit-scrollbar-track 
{
    background: #b1b1b1;
}

.divTimeStampDesktopAortic::-webkit-scrollbar-thumb 
{
    background-color: #a52337;
    border-radius: 30px;
}

.divTimeStampDesktopMitral::-webkit-scrollbar-thumb 
{
    background-color: #043c7b;
    border-radius: 30px;
}

.energySaving
{
    filter: grayscale(100%);
    position: absolute;
    top:0; 
    left:0;
    bottom:0; 
    right:0;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    min-width: 100vw;
    background-color:rgba(0,0,0,0.8)!important;
    z-index: 999999999999999999999;
    transition-duration: 1s;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.energySaving > img
{
    max-height: 400px;
    width: auto;
}

.energySaving > div
{
    position: absolute;
    bottom: 70px;
    text-align: center;
    padding: 20px;
}

.energySaving > div p
{
    color: gray;
    font-size: x-large;
    font-weight: bolder;
    margin-bottom: 15px;
}

.energySaving > div span
{
    color: gray;
    font-size: smaller;
}

/*
=============== 
Modal
===============
*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    transition: all 0.3s linear;
    visibility: hidden;
    z-index: -1;
  }
  /* OPEN/CLOSE MODAL */
  .show-modal {
    visibility: visible;
    z-index: 999;
  }
  .modal-container {
    background: #F0F0F0;
    border-radius: 0.25rem;
    width: 90vw;
    height: 90vh;
    max-width: 80%;
    /* max-width: 700px; */
    /* max-width: 620px; */
    text-align: center;
    display: grid;
    place-items: center;
    position: relative;
    overflow: auto;
  }
  .close-modal-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: hsl(360, 67%, 44%);
    cursor: pointer;
  }
/*
=============== 
Modal
===============
*/

